import React from 'react';

import { Transformer } from "react-konva";

const DesignWrapper = ({ isSelected, trRef, children, enabledAnchors = ['top-left', 'top-right', 'bottom-left', 'bottom-right'] }) => {
  return (
    <React.Fragment>
      {children}

      {isSelected && (
        <Transformer
          keepRatio={true}
          rotateEnabled={false}
          enabledAnchors={enabledAnchors}
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  )
}

export default DesignWrapper