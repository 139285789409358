import { Button, HStack } from "@chakra-ui/react";

const ChooseSize = ({ designSize, isDisabled }) => (
  <HStack>
    <Button isDisabled={isDisabled} onClick={() => designSize('A3')} size="xs">A3</Button>
    <Button isDisabled={isDisabled} onClick={() => designSize('A4')} size="xs">A4</Button>
    <Button isDisabled={isDisabled} onClick={() => designSize('A5')} size="xs">A5</Button>
    <Button isDisabled={isDisabled} onClick={() => designSize('A6')} size="xs">A6</Button>
    <Button isDisabled={isDisabled} onClick={() => designSize('A7')} size="xs">A7</Button>
  </HStack>
)

export default ChooseSize;