import { FormControl, FormLabel, Input } from "@chakra-ui/react";

const InputColor = ({ color, onChange, label = "Color" }) => {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Input value={color} onChange={(e) => onChange(e.target.value)} type="color" />
    </FormControl>
  )
}

export default InputColor;