import { Box, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Divider } from "@chakra-ui/react";
import InputDesign from "./InputDesign";
import ChooseSize from "./ChooseSize";
import { cmToPx, pxToCM } from "../../helper";
import React, { useContext, useMemo } from "react";
import InputSize from "./InputSize";
import SizePreview from "./SizePreview";
import FormText from "./FormText";
import { designerContext } from "../../lib/designerContext";
import AlignmentTool from "./AlignmentTool";

const Sidebar = ({
  kind,
  selectedSize,
  max_file_size,
  errors,
  input_errors,
  setSelectedSize,
  getSizes,
  handeSubmit,
}) => {
  const frontDReft = React.useRef()
  const backDReft = React.useRef()

  const { view, setView, backDesigns, frontDesigns, clientCanvas, currentDesignsView, selectedLayer, setCurrentDesignsView } = useContext(designerContext)

  function clearSelectedSize(direction) {
    setSelectedSize({
      ...selectedSize,
      [direction]: {
        size: null,
        name: null,
        cost: 0
      }
    })
  }

  const convertCmToVirtualSize = (width, height) => {
    const diffW = clientCanvas.diffWidth
    const diffH = clientCanvas.diffHeight

    return {
      width: (diffW / 100) * cmToPx(width),
      height: (diffH / 100) * cmToPx(height),
    }
  }

  const design = currentDesignsView.find((d) => d.type === "IMAGE")
  const isSizeChangesEnable = Boolean(design)

  function changeDesignSize(width = 0, height = 0) {
    if (!design) return

    const _width = height !== 0 ? design.config.width * (height / design.config.height) : width;
    const _height = width !== 0 ? design.config.height * (width / design.config.width) : height;

    if (isNaN(_width) || isNaN(_height)) return

    const targetLayerIndex = currentDesignsView.findIndex((d) => d.config.id === design.config.id)
    let tempLayers = [...currentDesignsView]

    tempLayers[targetLayerIndex] = {
      ...design,
      config: {
        ...design.config,
        width: _width,
        height: _height,
      }
    }

    setCurrentDesignsView(tempLayers)
  }

  function designSize(SIZE) {
    const sizes = getSizes(view == 'front' ? kind.surfaces[1].sizes : kind.surfaces[0].sizes)

    let newW
    switch (SIZE) {
      case 'A3':
        newW = sizes.filter(e => e.size === 'A3')[0].width
        break;
      case 'A4':
        newW = sizes.filter(e => e.size === 'A4')[0].width
        break;
      case 'A5':
        newW = sizes.filter(e => e.size === 'A5')[0].width
        break;
      case 'A6':
        newW = sizes.filter(e => e.size === 'A6')[0].width
        break;
      case 'A7':
        newW = sizes.filter(e => e.size === 'A7')[0].width
        break;
    }

    changeDesignSize(newW)
  }

  // default unit is cm, in SizePreview and InputSize components convert the unit to inch
  const sizes = useMemo(() => {
    return {
      front: {
        width: frontDesigns.length > 0 ? pxToCM(frontDesigns[0].config.width * 100 / clientCanvas.diffWidth, 2) : 0,
        height: frontDesigns.length > 0 ? pxToCM(frontDesigns[0].config.height * 100 / clientCanvas.diffHeight, 2) : 0
      },
      back: {
        width: backDesigns.length > 0 ? pxToCM(backDesigns[0].config.width * 100 / clientCanvas.diffWidth, 2) : 0,
        height: backDesigns.length > 0 ? pxToCM(backDesigns[0].config.height * 100 / clientCanvas.diffHeight, 2) : 0
      }
    }
  }, [frontDesigns, backDesigns, frontDesigns, backDesigns, clientCanvas])



  return (
    <Box width={{ base: '100%', lg: '500px' }} shadow="0px 0px 11px 0px #0000000d" display={{ base: 'block', lg: 'flex' }} bg="white">
      <AlignmentTool />

      <Stack overflow="hidden" width={{ base: '100%', lg: '500px' }} py={{ base: '3', lg: '10' }} px={{ base: '3', lg: '8' }} spacing='5' boxShadow={{ base: '0 -4px 8px 0px #00000014', lg: '-4px 0 8px 0px #00000014' }}>

        <SizePreview
          width={selectedLayer ? pxToCM(selectedLayer.config.width * 100 / clientCanvas.diffWidth, 2) : 0}
          height={selectedLayer ? pxToCM(selectedLayer.config.height * 100 / clientCanvas.diffHeight, 2) : 0}
        />

        <form onSubmit={handeSubmit}>
          <Stack spacing="5">
            <Tabs>
              <TabList>
                <Tab onClick={() => setView('front')}>Front</Tab>
                <Tab onClick={() => setView('back')}>Back</Tab>
              </TabList>

              <TabPanels>
                <TabPanel px={0} pb={0}>
                  <Stack spacing='5'>
                    <ChooseSize isDisabled={!isSizeChangesEnable} designSize={designSize} />

                    <InputDesign
                      label="Front Design"
                      name="front_design_file"
                      cost={selectedSize.front}
                      errors={errors}
                      design={design}
                      input_errors={input_errors}
                      dRef={frontDReft}
                      clearSelectedSize={clearSelectedSize}
                      max_file_size={max_file_size}
                    />

                    <InputSize
                      width={design ? sizes['front'].width : 0}
                      height={design ? sizes['front'].height : 0}
                      isDisabled={!isSizeChangesEnable || !design}
                      changeDesignSize={changeDesignSize}
                      convertCmToVirtualSize={convertCmToVirtualSize}
                    />
                  </Stack>
                </TabPanel>

                <TabPanel px={0} pb={0}>
                  <Stack spacing='5'>
                    <ChooseSize isDisabled={!isSizeChangesEnable} designSize={designSize} />

                    <InputDesign
                      label="Back Design"
                      name="back_design_file"
                      cost={selectedSize.back}
                      errors={errors}
                      input_errors={input_errors}
                      dRef={backDReft}
                      clearSelectedSize={clearSelectedSize}
                      max_file_size={max_file_size}
                      view={view}
                      clientCanvas={clientCanvas}
                    />

                    <InputSize
                      width={design ? sizes['back'].width : 0}
                      height={design ? sizes['back'].height : 0}
                      isDisabled={!isSizeChangesEnable || !design}
                      changeDesignSize={changeDesignSize}
                      convertCmToVirtualSize={convertCmToVirtualSize}
                    />

                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <Divider />

            <FormText />
          </Stack>
        </form>
      </Stack>
    </Box>
  )
}

export default Sidebar;