import moment from 'moment'
import numeral from 'numeral'

export function returnVal(dataset, colum, val, target) {
  var data = dataset.filter(e => e[colum] === val)

  return target ? data[0][target] : data[0]
}

export function getCol(matrix, target, val, get) {
  var column = [];
  for (var i = 0; i < matrix.length; i++) {
    if (val && target) {
      if (matrix[i][target] === val) {
        if (get) {
          column.push(matrix[i][get]);
        } else {
          column.push(matrix[i]);
        }
      }
    } else {
      column.push(matrix[i][get]);
    }
  }
  return column;
}

export function toPrice(val) {
  return numeral(val).format(',')
}

export function toDate(val = null, isFull = true) {
  var myMoment = val ? moment(val) : moment()
  if (isFull) {
    return myMoment.format('DD MMM YYYY - H:m')
  } else {
    return myMoment.format('DD MMM YYYY')
  }
}

export function withParams() {
  let params = window.location.href.match(/\?.*/)
  if (params) {
    return params[0]
  }
  return ''
}

export function returnId(id) {
  return id.toString().padStart(11, '0')
}

const cmInPX = 37.795275591

export function cmToPx(cm, decimal = null) {
  let result = cm * cmInPX
  return decimal === null ? result : result.toFixed(decimal)
}

export function pxToCM(px, decimal = null) {
  let result = px / cmInPX
  return decimal === null ? result : result.toFixed(decimal)
}

export function cmToInch(cm, decimal = null) {
  const result = cm / 2.54
  return decimal === null ? result : result.toFixed(decimal)
}

export function inchToCm(inch, decimal = null) {
  const result = inch * 2.54
  return decimal === null ? result : result.toFixed(decimal)
}

export function splitName(fullname) {
  let tempName = fullname.toLowerCase().split(' ')

  if (tempName.length > 1) {
    return {
      lastName: tempName.pop(),
      firstName: tempName.join(' '),
    }
  }

  return {
    firstName: fullname.toLowerCase(),
    lastName: ''
  }
}

export function getDiscountAmount({ productCost, shippingCost, coupon }) {
  let targetDiscountAmount = 0
  switch (coupon.target) {
    case 'SHIPPING':
      targetDiscountAmount = shippingCost
      break;
    case 'PROSHIP':
      targetDiscountAmount = productCost + shippingCost
      break;
    default:
      targetDiscountAmount = productCost
      break;
  }

  let discountAmount = coupon.type === 'numeric' ? coupon.value : ((coupon.value) / 100) * (targetDiscountAmount)

  if (coupon.type === 'percentage' && coupon.max_discount > 0 && discountAmount > coupon.max_discount) {
    discountAmount = coupon.max_discount
  }

  return discountAmount
}