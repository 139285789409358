import { FormControl, FormLabel, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"

const InputSize = ({ onChange, size, min = 1, label = 'Font Size' }) => {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <NumberInput
        value={size}
        step={1}
        min={min}
        onChange={onChange}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  )
}

export default InputSize;