import { FormControl, FormLabel, HStack, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react";
import { cmToInch, inchToCm } from "../../helper";
import { useDebounce } from "../../lib/hooks/useDebounce";

// TODO: fix change height

const InputSize = ({ width, height, isDisabled, changeDesignSize, convertCmToVirtualSize }) => {
  const enableChanges = useRef(false)

  const [sizes, setSizes] = useState({ width: cmToInch(width, 2), height: cmToInch(height, 2) })

  useEffect(() => {
    setSizes({ width: cmToInch(width, 2), height: cmToInch(height, 2) })
  }, [width, height])

  const sizesDebounce = useDebounce(sizes, 500)

  useEffect(() => {
    const parsedWidth = parseFloat(sizesDebounce.width)
    const parsedHeight = parseFloat(sizesDebounce.height)

    if (isDisabled || !enableChanges.current) return
    if (sizesDebounce.width === "" || sizesDebounce.height === "") return
    if (isNaN(parsedWidth) || isNaN(parsedHeight)) return
    if (!Boolean(parseFloat(parsedWidth)) || !Boolean(parsedHeight)) return

    const { width: _width, height: _height } = convertCmToVirtualSize(inchToCm(parsedWidth), inchToCm(parsedHeight))

    const isWidthHasChanges = parseFloat(cmToInch(width, 2)) !== parsedWidth
    const isHeightHasChanges = parseFloat(cmToInch(height, 2)) !== parsedHeight

    changeDesignSize(isWidthHasChanges ? _width : 0, isHeightHasChanges ? _height : 0)

    enableChanges.current = false
  }, [sizesDebounce])

  return (
    <HStack>
      <FormControl>
        <FormLabel>Width (inch)</FormLabel>
        <NumberInput
          value={sizes.width}
          precision={2}
          step={0.25}
          min={1}
          onChange={(width) => {
            enableChanges.current = true
            setSizes((prev) => ({ ...prev, width }))
          }}
          isDisabled={isDisabled}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <FormControl>
        <FormLabel>Height (inch)</FormLabel>
        <NumberInput
          value={sizes.height}
          precision={2}
          step={0.25}
          min={1}
          onChange={(height) => {
            enableChanges.current = true
            setSizes((prev) => ({ ...prev, height }))
          }}
          isDisabled={isDisabled}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </HStack>
  )
}

export default InputSize;