import { SimpleGrid } from "@chakra-ui/react"
import InputSize from "./InputSize"
import InputColor from "./InputColor"

const InputOutline = ({ onChange, textLayer }) => {

  return (
    <SimpleGrid columns={2} spacing={3}>
      <InputSize
        label="Outline Size"
        min={0}
        size={textLayer.config.strokeWidth}
        onChange={(strokeWidth) => {
          onChange({ strokeWidth })
        }}
      />

      <InputColor
        label="Outline Color"
        color={textLayer.config.stroke}
        onChange={(stroke) => {
          onChange({ stroke })
        }}
      />
    </SimpleGrid>
  )
}

export default InputOutline;