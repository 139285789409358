import { createContext, useState } from "react";

export const designerContext = createContext();

export const DesignerContextProvider = ({ children }) => {
  const [values, setValues] = useState({
    selectedLayer: null,
    frontDesigns: [],
    backDesigns: [],
    view: 'front',
    clientCanvas: {
      canvasWidth: 0,
      canvasHeight: 0,
      diffWidth: 0,
      diffHeight: 0,
    },
    mockupWrapper: {
      width: 0,
      height: 0
    }
  })

  const currentDesignsView = values.view === 'front' ? values.frontDesigns : values.backDesigns;
  const currentPropertyDesignName = values.view === 'front' ? 'frontDesigns' : 'backDesigns'

  const setSelectedLayer = (selectedLayer) => setValues((prev) => ({ ...prev, selectedLayer }))
  const setView = (view) => setValues((prev) => ({ ...prev, view, selectedLayer: null }))
  const setClientCanvas = ({ canvasWidth, canvasHeight, diffWidth, diffHeight }) => setValues((prev) => ({ ...prev, clientCanvas: { canvasWidth, canvasHeight, diffWidth, diffHeight } }))
  const setMockupWrapper = ({ width, height }) => setValues((prev) => ({ ...prev, mockupWrapper: { width, height } }))

  const setCurrentDesignsView = (designs) => {
    const uptodateSelectedLayer = values.selectedLayer ? designs.find((d) => d.config.id === values.selectedLayer.config.id) : null
    setValues((prev) => ({ ...prev, [currentPropertyDesignName]: designs, selectedLayer: uptodateSelectedLayer }))
  }

  const setFrontDesigns = (frontDesigns) => setCurrentDesignsView(frontDesigns)
  const setBackDesigns = (backDesigns) => setCurrentDesignsView(backDesigns)

  const deleteLayerFromCurrentView = (layerId) => {
    setValues((prev) => ({
      ...prev,
      selectedLayer: null,
      [currentPropertyDesignName]: prev[currentPropertyDesignName].filter((d) => d.config.id !== layerId)
    }))
  }

  return (
    <designerContext.Provider
      value={{
        ...values,
        currentDesignsView,
        setSelectedLayer,
        setFrontDesigns,
        setBackDesigns,
        setView,
        setClientCanvas,
        setMockupWrapper,
        setCurrentDesignsView,
        deleteLayerFromCurrentView
      }}
    >
      {children}
    </designerContext.Provider>
  )
}