import { createContext, useContext, useEffect, useState, useTransition } from "react";

const fontContext = createContext();

export const useFontContext = () => useContext(fontContext)

export const FontContextProvider = ({ children }) => {
  const [fontOptions, setFontOptions] = useState([])
  const [loadedFonts, setLoadedFonts] = useState([]);

  const [isFontOptionsLoading, startGetFontOptions] = useTransition()
  const [isFontInLoad, setIsFontInLoad] = useState(false)

  const downloadFont = async (family, url) => {
    setIsFontInLoad(true)
    const font = new FontFace(family, `url(${url.replace('http', 'https')})`)
    await font.load().then(() => {
      document.fonts.add(font)
      setIsFontInLoad(false)
    })
  }

  const loadFont = (family) => {
    const font = fontOptions.find((f) => f.family === family)
    if (!font) return
    if (loadedFonts.some((f) => f.family === font.family)) return // skip font if already loaded

    downloadFont(font.family, font.files.regular)
    setLoadedFonts([...loadedFonts, font])
  }

  useEffect(() => {
    startGetFontOptions(() => fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDU2uz1LOH5zc58vhfoGI5OJ8vt1eTUhhE&sort=popularity').then(async (res) => {
      const results = await res.json()
      const options = results.items
      setFontOptions(options)

      const defaultFont = options.find((f) => f.family === 'Roboto')
      if (!defaultFont) return console.warn('The default font is not found')
      downloadFont(defaultFont.family, defaultFont.files.regular)
      setLoadedFonts([defaultFont])
    }))

    return () => document.fonts.clear()
  }, [])

  return (
    <fontContext.Provider value={{
      isFontOptionsLoading,
      isFontInLoad,
      fontOptions,
      loadFont
    }}>
      {children}
    </fontContext.Provider>
  )
}