import { Box, Stack } from "@chakra-ui/react"
import Mockup from "./Mockup"
import React, { useContext } from "react"
import { designerContext } from "../lib/designerContext"

const DesignArea = ({
  tshirt_base,
  elStage,
  values
}) => {
  const { view, clientCanvas, frontDesigns, backDesigns, setFrontDesigns, setBackDesigns } = useContext(designerContext)
  const [selectedBaseColor, setBaseColor] = React.useState(values.color.indexOf('7') >= 0 ? 'white' : 'black')

  return (
    <Box w="100%" px={{ base: "5", lg: "10" }} py="10" pos="relative" height="auto">
      <Box width={{ base: '100%', lg: '68%' }} margin="auto" id="mockup-wrapper" ref={elStage} position="sticky" top="10">

        <Mockup canvasTop={19.32} imageBase={tshirt_base.front[selectedBaseColor]} isShow={view === 'front'} designs={frontDesigns} updateDesign={setFrontDesigns} elStage={elStage} canvasWidth={clientCanvas.canvasWidth} canvasHeight={clientCanvas.canvasHeight} />

        <Mockup canvasTop={12.05} imageBase={tshirt_base.back[selectedBaseColor]} isShow={view === 'back'} designs={backDesigns} updateDesign={setBackDesigns} elStage={elStage} canvasWidth={clientCanvas.canvasWidth} canvasHeight={clientCanvas.canvasHeight} />

        <Box pos="absolute" bottom="0">
          <Stack>
            <Box onClick={() => setBaseColor('white')} display="flex" justifyContent="center" alignItems="center" boxShadow={selectedBaseColor === 'white' ? '0 0 0 3px rgb(66 153 225 / 60%)' : 'none'} as="button" width="50px" height="50px" bg="gray.100" borderRadius="full">
              <Box boxShadow="base" width="35px" height="35px" bg="white" borderRadius="full" />
            </Box>

            <Box onClick={() => setBaseColor('black')} display="flex" justifyContent="center" alignItems="center" boxShadow={selectedBaseColor === 'black' ? '0 0 0 3px rgb(66 153 225 / 60%)' : 'none'} as="button" width="50px" height="50px" bg="gray.100" borderRadius="full">
              <Box boxShadow="base" width="35px" height="35px" bg="black" borderRadius="full" />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default DesignArea