import { Box, Button, IconButton, Input, Stack, Text, useToast } from "@chakra-ui/react";
import FormInput, { InputContext } from "../FormInput";
import { FaRegTrashAlt } from "react-icons/fa";
import { useContext } from "react";
import { designerContext } from "../../lib/designerContext";

const InputDesign = ({
  label,
  name,
  errors,
  input_errors,
  design,
  dRef,
  cost,
  clearSelectedSize,
  max_file_size,
}) => {
  const { clientCanvas, mockupWrapper, deleteLayerFromCurrentView, currentDesignsView, setCurrentDesignsView, setSelectedLayer } = useContext(designerContext)

  const toast = useToast()

  function changeDesign(e) {
    if (!e.target.files.length > 0) {
      dRef.current.value = ''
      return
    }

    if (e.target.files[0].size > (max_file_size * 1024)) {
      dRef.current.value = ''
      toast({
        description: `Image size can't be more than ${max_file_size / 1024} MB`,
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return
    }

    const file = e.target.files[0]
    const reader = new FileReader();

    reader.addEventListener('load', function () {
      // console.log(reader.result)
      var image = new Image();
      image.src = reader.result;

      image.onload = function () {
        // access image size here
        const width = calculate_image_dimesion('width', this.width, this.height)
        const height = calculate_image_dimesion('height', this.width, this.height)

        const dataLayer = {
          type: 'IMAGE',
          asset: reader.result,
          base64: reader.result,
          file: file,
          config: {
            x: 0,
            y: 0,
            id: 'image-1',
            base: {
              width: mockupWrapper.width,
              height: ((91.332 * mockupWrapper.width) / 100).toFixed(2)
            },
            width: width,
            height: height,
            canvas: {
              width: clientCanvas.canvasWidth,
              height: clientCanvas.canvasHeight
            },
            rotation: -0,
          }
        }

        setCurrentDesignsView([...currentDesignsView, dataLayer])
        setSelectedLayer(dataLayer)
      };
    })
    reader.readAsDataURL(file)
  }

  function calculate_image_dimesion(type, originalWidth, originalHeight) {
    const canvas = clientCanvas.canvasWidth

    if (originalWidth >= canvas) {
      const maxWidth = canvas
      const maxHeight = clientCanvas.canvasHeight
      var ratio = 0;  // Used for aspect ratio
      var width = originalWidth;    // Current image width
      var height = originalHeight;  // Current image height

      let newWidth = maxWidth;
      let newHeight = maxWidth;

      if (width > maxWidth && width > height) {
        ratio = width / height;
        newHeight = maxWidth / ratio;
        newWidth = maxWidth

      } else if (height > maxHeight && height > width) {
        ratio = height / width;
        newWidth = maxHeight / ratio
        newHeight = maxHeight;
      }

      if (type === 'width') {
        return newWidth
      } else {
        return newHeight
      }
    }
    return originalWidth
  }

  return (
    <FormInput label={label} name={name} errors={errors} input_errors={input_errors}>
      <Stack>
        <InputContext.Consumer>
          {({ inputRef }) => (
            <Box>
              <Input id={name} ref={dRef} onChange={(e) => changeDesign(e)} type="file" accept=".jpg,.png,.jpeg" hidden />
              <Box display="flex" border="solid #E2E8F0 1px" rounded="lg">
                <Button ref={inputRef} _focus={{ boxShadow: "outline" }} _active={{ bg: 'none' }} _hover={{ bg: 'none' }} p="0" bg="white" w="full" >
                  <Box as="label" htmlFor={name} display="flex" px="1rem" cursor="pointer" h="full" w="full" alignItems="center" fontWeight="normal">
                    <Text isTruncated>{design && design.file ? design.file.name : 'Choose file'}</Text>
                  </Box>
                </Button>
                <IconButton
                  isDisabled={!design}
                  colorScheme="red"
                  aria-label="Delete"
                  icon={<FaRegTrashAlt />}
                  onClick={() => {
                    dRef.current.value = '';
                    clearSelectedSize(name == 'front_design_file' ? 'front' : 'back');
                    deleteLayerFromCurrentView(design?.config.id)
                  }}
                />
              </Box>
            </Box>
          )}
        </InputContext.Consumer>
        {cost.name && (
          <Text fontSize="sm">Size: {cost.name}</Text>
        )}
      </Stack>
    </FormInput>
  )
}

export default InputDesign;