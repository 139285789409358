import React, { useEffect, useMemo, useRef } from 'react';
import DesignWrapper from './DesignWrapper';
import { Text } from 'react-konva';
import { useFontContext } from '../../lib/fontContext';

const LayerText = ({ isSelected, onSelect, designProp, onChange }) => {
  const shapeRef = useRef();
  const trRef = useRef();

  const { isFontInLoad } = useFontContext()

  const layerConfig = useMemo(() => {
    return {
      x: designProp.config.x,
      y: designProp.config.y,
      fontFamily: designProp.config.fontFamily,
      fontSize: designProp.config.fontSize,
      fill: designProp.config.fill,
      align: designProp.config.align,
      textDecoration: designProp.config.textDecoration,
      strokeWidth: designProp.config.strokeWidth,
      stroke: designProp.config.stroke,
      fillAfterStrokeEnabled: designProp.config.fillAfterStrokeEnabled
    }
  }, [designProp.config, designProp.config.textDecoration, designProp.config.align])

  useEffect(() => {
    if (!isSelected) return

    trRef.current.nodes([shapeRef.current]);
    trRef.current.getLayer().batchDraw();
  }, [isSelected]);

  useEffect(() => {
    // get layer width n height
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.scaleX(1);
    node.scaleY(1);

    const width = Math.max(5, node.width() * scaleX)
    const height = Math.max(node.height() * scaleY)

    onChange({
      ...designProp,
      config: {
        ...designProp.config,
        width: width,
        height: height,
      }
    })
  }, [layerConfig.fontFamily, layerConfig.fontSize, designProp.content])

  useEffect(() => {
    if (isFontInLoad) return
    if (!trRef.current) return

    setTimeout(() => {
      trRef.current.forceUpdate();
    }, 500)
  }, [isFontInLoad])

  return (
    <DesignWrapper isSelected={isSelected} trRef={trRef} enabledAnchors={[]}>
      <Text
        ref={shapeRef}
        text={designProp.content}
        onClick={onSelect}
        onTap={onSelect}
        draggable
        {...layerConfig}
        onDragStart={() => {
          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              isDragging: true,
            }
          })
        }}

        onDragEnd={(e) => {
          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              x: e.target.x(),
              y: e.target.y(),
            }
          })
        }}

        onTransformEnd={e => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);

          const width = Math.max(5, node.width() * scaleX)
          const height = Math.max(node.height() * scaleY)

          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: width,
              height: height,
              // fontSize: height,
              rotation: node.rotation()
            }
          });
        }}
      />
    </DesignWrapper>
  )
}

export default LayerText