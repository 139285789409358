import React, { useContext } from 'react'
import Layout from './Layout'
import { Box, Center } from '@chakra-ui/react'
import FormHandler from './FormHandler'
import { toPrice, cmToPx } from '../helper'
import DesignArea from './DesignArea'
import Sidebar from './Sidebar'
import { designerContext } from '../lib/designerContext'

function Designer({ errors, profit_min, kind, tshirt_base, edit, selected_variants, max_file_size }) {
  // elStage is used to obtain the width of the mockup t-shirt div. To check how it used check on Designer.js
  const elStage = React.useRef();

  const { clientCanvas, frontDesigns, view, backDesigns, setClientCanvas, setFrontDesigns, setBackDesigns, setMockupWrapper } = useContext(designerContext)

  const [selectedSize, setSelectedSize] = React.useState({
    front: {
      size: null,
      name: null,
      cost: 0
    },
    back: {
      size: null,
      name: null,
      cost: 0
    }
  })

  const initData = {
    name: edit ? edit.name : '',
    is_private: edit ? edit.is_private : false,
    description: edit ? edit.description != null ? edit.description : "" : "",
    profit: edit ? parseInt(edit.profit) : 10000,
    discount: edit ? edit.discount : 0,
    themes: edit ? edit.themes.map((e) => ({ label: e.name, value: e.id })) : [],
    ...selected_variants
  }

  function onSubmit(vals, setSubmitting) {
    var data = new FormData()
    data.append('name', vals.name)
    data.append('description', vals.description)
    data.append('profit', vals.profit)
    data.append('is_private', vals.is_private ? 1 : 0)
    data.append('discount', vals.discount)

    if (edit) {
      data.append('_method', 'put')
    }

    if (frontDesigns.length > 0) {
      const { isDragging, ...newData } = frontDesigns[0].config

      data.append('front_asset', JSON.stringify({
        config: frontDesigns ? newData : null
      }))
      if (frontDesigns[0].file) {
        data.append('front_design_file', frontDesigns[0].file)
      }
    }

    if (backDesigns.length > 0) {
      const { isDragging, ...newData } = backDesigns[0].config
      data.append('back_asset', JSON.stringify({
        config: backDesigns ? newData : null
      }))
      if (backDesigns[0].file) {
        data.append('back_design_file', backDesigns[0].file)
      }
    }

    // add variants data
    kind.variants.forEach(e => {
      let isExist = values[e.code] !== undefined
      if (isExist) {
        values[e.code].forEach((eSub, i) => {
          data.append(`${e.code}[${i}]`, eSub)
        });
      }
    });

    // add themes
    for (let index = 0; index < vals.themes.length; index++) {
      const theme = vals.themes[index];
      data.append(`themes[${index}]`, theme.value)
    }
  }

  function validate(val) {
    const errors = {}

    if (val.name === '') {
      errors.name = 'Nama produk tidak boleh kosong'
    } else if (parseInt(val.profit) < profit_min) {
      errors.profit = 'Profit minimal Rp ' + toPrice(profit_min)
    } else if (val.discount < 0) {
      errors.discount = 'Diskon tidak valid'
    } else if (val.discount >= parseInt(val.profit)) {
      errors.discount = 'Diskon tidak boleh sama atau lebih dari harga profit'
    } else if (val.discount > 0 && (parseInt(val.profit) - val.discount) < profit_min) {
      errors.discount = 'Proft setelah potongan (' + (parseInt(val.profit) - val.discount) + ') tidak boleh kurang dari minimal profit Rp' + toPrice(profit_min)
    }

    return errors
  }

  const { values, input_errors, handeSubmit, handleChange } = FormHandler(initData, validate, onSubmit)

  React.useEffect(() => {
    const mockupWrapper = document.getElementById('mockup-wrapper')

    let canvasWidth = Math.round(42 * mockupWrapper.clientWidth / 100)
    let canvasHeight = Math.round(57 * mockupWrapper.clientWidth / 100)
    let diffWidth = (canvasWidth / cmToPx(30)) * 100
    let diffHeight = (canvasHeight / cmToPx(40)) * 100

    setClientCanvas({
      canvasWidth: canvasWidth,
      canvasHeight: canvasHeight,
      diffWidth: diffWidth,
      diffHeight: diffHeight,
    })

    setMockupWrapper({
      width: mockupWrapper.clientWidth,
      height: mockupWrapper.clientWidth
    })

    if (edit) {
      // add design
      const currentCanvasWidth = canvasWidth
      const currentCanvasHeight = canvasHeight

      edit.assets.forEach((asset) => {
        let setDesign = asset.surface === 'front' ? setFrontDesigns : setBackDesigns
        let resultWidth = (currentCanvasWidth / asset.config.config.canvas.width) * asset.config.config.width
        let resultHeight = asset.config.config.height * (resultWidth / asset.config.config.width)

        convertImgToBase64(asset.files[0].url, (data) => {
          setDesign([
            {
              asset: data,
              base64: data,
              config: {
                x: (currentCanvasWidth / asset.config.config.canvas.width) * asset.config.config.x,
                y: (currentCanvasWidth / asset.config.config.canvas.width) * asset.config.config.y,
                id: asset.config.config.id,
                base: { width: mockupWrapper.clientWidth, height: ((91.332 * mockupWrapper.clientWidth) / 100).toFixed(2) },
                width: resultWidth,
                canvas: { width: currentCanvasWidth, height: currentCanvasHeight },
                height: resultHeight,
                rotation: asset.config.config.rotation,
              }
            }
          ])
        })
      });
    }
  }, [])

  const getSizes = (mySizes, diff = null) => {
    const diffW = diff ? diff.diffWidth : clientCanvas.diffWidth
    const diffH = diff ? diff.diffHeight : clientCanvas.diffHeight

    // create virtual size in pixel base on diff size
    const sizes = mySizes.map((size) => {
      return {
        ...size.size,
        width: (diffW / 100) * cmToPx(size.size.width),
        height: (diffH / 100) * cmToPx(size.size.height),
      }
    })

    //  short
    sizes.forEach((el1, i) => {
      sizes.forEach((el2, j) => {
        if (el1.width < el2.width) {
          var temp = sizes[i]
          sizes[i] = sizes[j]
          sizes[j] = temp
        }
      })
    });

    return sizes
  }

  const calcSelectedSize = (width, height, direction, diff = null) => {
    // console.log(direction)
    const sizes = getSizes(direction == 'front' ? kind.surfaces[1].sizes : kind.surfaces[0].sizes, diff)

    let isSelected = false
    let size = null
    for (let index = 0; index < sizes.length; index++) {
      // console.log(sizes[index].name)
      // console.log('design w: ' + width + ' px')
      // console.log('design w to actual: ' + pxToCM(width) * 100 / clientCanvas.diffWidth + ' cm')
      // console.log('design h: ' + height)
      // console.log('actual w: ' + sizes[index].width)
      // console.log('actual h: ' + sizes[index].height)
      if (sizes[index].width >= width && sizes[index].height >= height) {
        size = {
          [direction]: {
            size: sizes[index].size,
            name: sizes[index].name,
            cost: sizes[index].additional_price
          }
        }

        if (!diff) {
          setSelectedSize({
            ...selectedSize, ...size
          })
        } else {
          return size
        }

        isSelected = true
        break
      }
    }

    if (!isSelected) {
      size = {
        [direction]: {
          size: sizes[sizes.length - 1].size,
          name: sizes[sizes.length - 1].name,
          cost: sizes[sizes.length - 1].additional_price
        }
      }

      if (!diff) {
        setSelectedSize({
          ...selectedSize, ...size
        })
      } else {
        return size
      }
    }
  }

  React.useEffect(() => {
    if (frontDesigns.length > 0) {
      calcSelectedSize(frontDesigns[0].config.width, frontDesigns[0].config.height, 'front')
    }
  }, [frontDesigns])

  React.useEffect(() => {
    if (backDesigns.length > 0) {
      calcSelectedSize(backDesigns[0].config.width, backDesigns[0].config.height, 'back')
    }
  }, [backDesigns])

  return (
    <Layout activeNav="products" hideHeader hideMobileNav hideFooter py="0" showWA={false}>
      <Box width="full" minH='100vh' bg="gray.50">

        <Center bg="white" position="relative" zIndex="99" py="3" shadow="md">
          <img width="50px" src="/logo192.png" alt="logo" />
        </Center>

        <Box minH='calc(100vh - 74px)' display={{ base: 'block', lg: 'flex' }} gap="10" w="full">
          <DesignArea
            tshirt_base={tshirt_base}
            elStage={elStage}
            values={values}
          />

          <Sidebar
            kind={kind}
            values={values}
            selectedSize={selectedSize}
            max_file_size={max_file_size}
            errors={errors}
            input_errors={input_errors}
            handleChange={handleChange}
            setSelectedSize={setSelectedSize}
            getSizes={getSizes}
            handeSubmit={handeSubmit}
          />
        </Box>
      </Box>
    </Layout>
  )
}

function convertImgToBase64(url, callback, outputFormat) {
  var canvas = document.createElement('CANVAS');
  var ctx = canvas.getContext('2d');
  var img = new Image;
  img.crossOrigin = 'Anonymous';
  img.onload = function () {
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL(outputFormat || 'image/png');
    callback.call(this, dataURL);
    // Clean up
    canvas = null;
  };
  img.src = url;
}

export default Designer;
