import React, { useContext } from 'react'

import { Box, Center } from '@chakra-ui/react'
import { Stage, Layer } from 'react-konva';
import { designerContext } from '../../lib/designerContext';
import LayerImage from './LayerImage';
import LayerText from './LayerText';

// canvasTop is used to provide a distance from the top position of the design print area. 
// For example, the print area will be given a certain distance from the neckline.

function Mockup({ elStage, updateDesign, designs, isShow, imageBase, canvasTop, direction, canvasWidth, canvasHeight }) {
  const { selectedLayer, setSelectedLayer } = useContext(designerContext)
  const [pageLoaded, setPageLoaded] = React.useState(false)

  const clientHeight = pageLoaded ? ((91.25 / 100) * elStage.current.offsetWidth).toFixed(2) : 0
  const layerRef = React.useRef(null);

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedLayer(null);
    }
  };

  React.useEffect(() => {
    setPageLoaded(true)
    layerRef.current.getCanvas()._canvas.id = 'frontCanvas';
    // console.log('designer loaded')
  }, [])

  return (
    <Box height="100%" display={isShow ? 'block' : 'none'}>
      <Center height="100%" w="full" pos="relative">
        <Box pos="absolute" top={`${((canvasTop / 100) * clientHeight).toFixed(2)}px`}>
          <Stage
            width={pageLoaded ? canvasWidth : 0}
            height={pageLoaded ? canvasHeight : 0}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
          >
            <Layer ref={layerRef}>
              {designs.map((design, i) => {
                const onSelect = () => setSelectedLayer(design);
                const onChange = (newAttrs) => {
                  const rects = designs.slice();
                  rects[i] = newAttrs;
                  updateDesign(rects);
                }

                const isSelected = design.config.id === selectedLayer?.config.id

                const props = {
                  key: i,
                  designProp: design,
                  isSelected: isSelected,
                  onSelect: onSelect,
                  onChange: onChange,
                  direction: direction
                }

                if (design.type === 'TEXT') {
                  return (
                    <LayerText {...props} />
                  )
                }

                return (
                  <LayerImage {...props} />
                );
              })}
            </Layer>
          </Stage>
        </Box>
        <img width="100%" alt='mockup' src={imageBase} />
      </Center>
    </Box>
  )
}


export default Mockup
