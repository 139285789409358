import React from 'react'

function FormHandler(iniitalValues, validate, onSubmit) {
  const [values, setValues] = React.useState(iniitalValues)
  const [input_errors, setError] = React.useState({})
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  function handleChange(event, isArray = false) {
    event.preventDefault()
    if (isArray) {
      let isExist = values[event.target.name] !== undefined
      let tempVals = []

      if (isExist) {
        tempVals = values[event.target.name]
        tempVals.push(event.target.value)
        let checkDataExist = tempVals.filter(e => e === event.target.value)
        if (checkDataExist.length > 1) {
          tempVals = tempVals.filter(e => e !== event.target.value)
        }
      } else {
        tempVals.push(event.target.value)
      }

      setValues({
        ...values,
        [event.target.name]: tempVals
      })
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      })
    }
  }

  function handleCustomChange(name, value) {
    setValues({
      ...values,
      [name]: value
    })
  }

  function handeSubmit(event, callBack) {
    event.preventDefault()
    const errors_result = validate(values)
    setError(errors_result)
    setIsSubmitting(true)
    if (Object.keys(errors_result).length === 0) {
      onSubmit(values, setIsSubmitting, callBack)
    } else {
      setIsSubmitting(false)
    }
  }

  function handleCustomSubmit(event, customSubmit) {
    event.preventDefault()
    const errors_result = validate(values)
    setError(errors_result)
    setIsSubmitting(true)
    if (Object.keys(errors_result).length === 0) {
      customSubmit(values)
      setIsSubmitting(false)
    } else {
      setIsSubmitting(false)
    }
  }

  // React.useEffect(() => {
  //     const err = Object.keys(input_errors).length === 0

  //     if (isSubmitting) {
  //         if (err) {
  //             onSubmit(values, setIsSubmitting)
  //             console.log('run')
  //         } else {
  //             setIsSubmitting(false)
  //         }
  //     }

  // }, [input_errors, values, onSubmit, isSubmitting])

  return { values, input_errors, isSubmitting, handleChange, handeSubmit, setValues, setError, handleCustomSubmit, handleCustomChange }
}


export default FormHandler
