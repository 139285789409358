import { FormControl, FormLabel } from "@chakra-ui/react"
import { useFontContext } from "../../../lib/fontContext"
import Select from 'react-select';

const InputFont = ({ onChange, selectedFont }) => {
  const { fontOptions, isFontOptionsLoading, isFontInLoad, loadFont } = useFontContext()

  const options = fontOptions.map((f) => ({ value: f.family, label: f.family }))

  const handleChange = ({ value }) => {
    const fontOption = options.find((o) => o.value === value)
    if (!fontOption) return

    loadFont(fontOption.value)
    onChange(fontOption.value)
  }

  return (
    <FormControl>
      <FormLabel>Font</FormLabel>
      <Select options={options} isLoading={isFontOptionsLoading} value={options.find((f) => f.value === selectedFont)} onChange={handleChange} isDisabled={isFontInLoad} />
    </FormControl>
  )
}

export default InputFont;