import { Button, FormControl, FormLabel, SimpleGrid, Stack, Textarea } from "@chakra-ui/react"
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { designerContext } from "../../../lib/designerContext";
import { useDebounce } from "../../../lib/hooks/useDebounce";
import InputFont from "./InputFont";
import InputColor from "./InputColor";
import InputSize from "./InputSize";
import InputOutline from "./InputOutline";

const FormText = () => {
  const enableInputRef = useRef(false)
  const { selectedLayer, mockupWrapper, clientCanvas, currentDesignsView, setCurrentDesignsView, setSelectedLayer, deleteLayerFromCurrentView } = useContext(designerContext)

  const defaultText = useMemo(() => {
    return {
      type: 'TEXT',
      content: 'Test',
      config: {
        x: 0,
        y: 0,
        id: `text-${currentDesignsView.filter((d) => d.type === 'TEXT').length + 1}`,
        fontFamily: 'Roboto',
        fill: '#000000',
        fontSize: 15,
        align: 'left',
        textDecoration: '',
        strokeWidth: 0,
        stroke: '#000000',
        fillAfterStrokeEnabled: true,
        base: {
          width: mockupWrapper.width,
          height: ((91.332 * mockupWrapper.width) / 100).toFixed(2)
        },
        width: undefined,
        height: undefined,
        canvas: {
          width: clientCanvas.canvasWidth,
          height: clientCanvas.canvasHeight
        },
        rotation: -0,
      }
    }
  }, [clientCanvas, currentDesignsView])

  const selectedText = selectedLayer !== null && selectedLayer.type === 'TEXT' ? selectedLayer : null

  const [textLayer, setTextLayer] = useState(null)

  useEffect(() => {
    if (!selectedText) return setTextLayer(null)
    setTextLayer(selectedLayer)
  }, [selectedLayer, selectedText])

  const textLayerDebounce = useDebounce(textLayer, 500)

  useEffect(() => {
    if (!enableInputRef.current) return
    if (!textLayer) return

    enableInputRef.current = false

    const fontSize = parseFloat(textLayer.config.fontSize)
    const strokeWidth = parseFloat(textLayer.config.strokeWidth)
    if (textLayer.config.fontSize === "" || textLayer.config.strokeWidth === "") return
    if (isNaN(fontSize) || isNaN(strokeWidth)) return
    if (!Boolean(fontSize)) return

    const targetLayerIndex = currentDesignsView.findIndex((d) => d.config.id === textLayer.config.id)
    let tempLayers = [...currentDesignsView]
    tempLayers[targetLayerIndex] = textLayer
    tempLayers[targetLayerIndex].config.fontSize = fontSize
    tempLayers[targetLayerIndex].config.strokeWidth = strokeWidth
    setCurrentDesignsView(tempLayers)

  }, [textLayerDebounce])

  const addNewText = () => {
    setCurrentDesignsView([...currentDesignsView, defaultText])
    setSelectedLayer(defaultText)
  }

  return (
    <Stack spacing={6}>
      <Button onClick={addNewText}>Add New Text</Button>

      {textLayer && (
        <>
          <FormControl>
            <FormLabel>Text</FormLabel>

            <Textarea
              value={textLayer.content}
              onChange={(e) => {
                enableInputRef.current = true
                setTextLayer((prev) => ({ ...prev, content: e.target.value }))
              }}
            />
          </FormControl>

          <InputFont
            selectedFont={textLayer.config.fontFamily}
            onChange={(fontFamily) => {
              enableInputRef.current = true
              setTextLayer((prev) => ({ ...prev, config: { ...prev.config, fontFamily } }))
            }}
          />

          <SimpleGrid columns={2} spacing={3}>
            <InputSize
              size={textLayer.config.fontSize}
              onChange={(fontSize) => {
                enableInputRef.current = true
                setTextLayer({ ...textLayer, config: { ...textLayer.config, fontSize } })
              }}
            />

            <InputColor
              color={textLayer.config.fill}
              onChange={(fill) => {
                enableInputRef.current = true
                setTextLayer((prev) => ({ ...prev, config: { ...prev.config, fill } }))
              }}
            />
          </SimpleGrid>

          <InputOutline
            textLayer={textLayer}
            onChange={(data) => {
              enableInputRef.current = true
              setTextLayer({ ...textLayer, config: { ...textLayer.config, ...data } })
            }}
          />

          <Button colorScheme="red" onClick={() => deleteLayerFromCurrentView(textLayer.config.id)}>Delete Text</Button>
        </>
      )}
    </Stack>
  )
}

export default FormText;
