import React from 'react'
import { FormControl, FormLabel, Input, Textarea, FormErrorMessage, FormHelperText, useRadio, useRadioGroup, Box, Text } from '@chakra-ui/react'
import Select from 'react-select'

export const InputContext = React.createContext()

function FormInput({ input_errors = {}, errors = {}, name, values, label, children, handleChange, type, hint, placeholder, isTextArea = false, isReadOnly = false, align = "left", max = null }) {
  const inputRef = React.useRef()

  React.useEffect(() => {
    if (input_errors[name] || (errors && errors[name])) {
      inputRef.current.focus()
    }
  }, [input_errors, errors, name])

  return (
    <FormControl isInvalid={input_errors[name] || (errors && errors[name])} id={name}>
      {label && (
        <FormLabel>{label}</FormLabel>
      )}
      {children ? (
        <InputContext.Provider value={{
          inputRef: inputRef,
          inputName: name
        }}>
          {children}
        </InputContext.Provider>
      ) : (
        <>
          {isTextArea ? (
            <>
              <Textarea textAlign={align} ref={inputRef} onChange={(e) => max ? e.target.value.length <= max ? handleChange(e) : null : handleChange(e)} value={values[name]} name={name} type={type} placeholder={placeholder} />
              {max && (
                <Text fontSize="sm" textAlign="right">{values[name] ? values[name].length : 0}/{max}</Text>
              )}
            </>
          ) : (
            <Input textAlign={align} ref={inputRef} onChange={handleChange} value={values[name]} name={name} type={type} placeholder={placeholder} readOnly={isReadOnly} />
          )}
        </>
      )}
      <FormErrorMessage w="full" d="block" textAlign={align}>{input_errors[name] || errors[name]}</FormErrorMessage>
      {hint && (
        <FormHelperText>{hint}</FormHelperText>
      )}
    </FormControl>
  )
}

export function SelectInput(props) {
  return <Select styles={{ menu: provided => ({ ...provided, zIndex: 99 }) }} {...props} ref={props.myref} />
}

export function RadioInput({ name, myref, options, handleChange, defaultValue = '' }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: defaultValue,
    onChange: e => handleChange(name, parseInt(e) || e)
  })

  const group = getRootProps()

  return (
    <Box {...group} m="-1" display="flex" flexWrap="wrap">
      {options.map((value) => {
        const radio = getRadioProps({ value: value.value, name: name })
        return (
          <RadioCard key={value.value} {...radio} myref={myref}>
            {value.label}
          </RadioCard>
        )
      })}
    </Box>
  )
}

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" m='1'>
      <input {...input} ref={props.myref} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        _checked={{
          bg: "blue.400",
          color: "white",
          borderColor: "blue.400",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  )
}

export default FormInput
