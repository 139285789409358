import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { MdOutlineInsertLink } from "react-icons/md";
import { cmToInch } from "../../helper";

const SizePreview = ({ width, height }) => (
  <HStack color="gray.500" spacing="3">
    <Box display="flex">
      <Text mr="3">Width</Text>
      <Text color="gray.800" mr="1">{cmToInch(width, 2)}</Text>
      <Text>inch</Text>
    </Box>

    <Icon as={MdOutlineInsertLink} w={6} h={6} />

    <Box display="flex">
      <Text mr="3">Height</Text>
      <Text color="gray.800" mr="1">{cmToInch(height, 2)}</Text>
      <Text>inch</Text>
    </Box>
  </HStack>
)

export default SizePreview;