import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import DesignWrapper from './DesignWrapper';

const LayerImage = ({ onSelect, isSelected, designProp, onChange }) => {
  const [image] = useImage(designProp.asset, 'Anonymous');
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <DesignWrapper isSelected={isSelected} trRef={trRef}>
      <Image
        ref={shapeRef}
        image={image}
        onClick={onSelect}
        onTap={onSelect}
        draggable
        {...designProp.config}

        onDragStart={() => {
          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              isDragging: true,
            }
          })
        }}

        onDragEnd={(e) => {
          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              x: e.target.x(),
              y: e.target.y(),
            }
          })
        }}

        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);

          const width = Math.max(5, node.width() * scaleX)
          const height = Math.max(node.height() * scaleY)
          onChange({
            ...designProp,
            config: {
              ...designProp.config,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: width,
              height: height,
              rotation: node.rotation()
            }
          });
        }}
      />
    </DesignWrapper>
  )
}

export default LayerImage