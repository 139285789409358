import { Divider, IconButton, Stack } from "@chakra-ui/react"
import { useContext } from "react"
import { MdAlignHorizontalCenter, MdAlignHorizontalLeft, MdAlignHorizontalRight, MdAlignVerticalBottom, MdAlignVerticalCenter, MdAlignVerticalTop, MdFormatAlignCenter, MdFormatAlignLeft, MdFormatAlignRight, MdFormatUnderlined, MdStrikethroughS } from "react-icons/md"
import { designerContext } from "../../lib/designerContext"

const AlignmentTool = () => {
  const { setCurrentDesignsView, selectedLayer, currentDesignsView } = useContext(designerContext)

  const onChangeAlignment = (config) => {
    const targetLayerIndex = currentDesignsView.findIndex((d) => d.config.id === selectedLayer.config.id)
    let tempLayers = [...currentDesignsView]
    tempLayers[targetLayerIndex] = { ...selectedLayer, config: { ...selectedLayer.config, ...config } }

    setCurrentDesignsView(tempLayers)
  }

  function designAlign(ALIGN) {
    if (!selectedLayer) return

    switch (ALIGN) {
      case 'HorizontalCenter':
        return onChangeAlignment({ x: (selectedLayer.config.canvas.width - selectedLayer.config.width) / 2 })
      case 'HorizontalLeft':
        return onChangeAlignment({ x: 0 })
      case 'HorizontalRight':
        return onChangeAlignment({ x: selectedLayer.config.canvas.width - selectedLayer.config.width })
      case 'VerticalBottom':
        return onChangeAlignment({ y: selectedLayer.config.canvas.height - selectedLayer.config.height })
      case 'VerticalCenter':
        return onChangeAlignment({ y: (selectedLayer.config.canvas.height - selectedLayer.config.height) / 2 })
      case 'VerticalTop':
        return onChangeAlignment({ y: 0 })
    }
  }

  const isDisabled = selectedLayer === null;

  const selectedText = selectedLayer !== null && selectedLayer.type === 'TEXT' ? selectedLayer : null
  const isTextAlignActive = (alignment) => selectedText && selectedText.config.align === alignment

  const onChangeTextAlign = (alignment) => {
    if (!selectedText) return
    const targetLayerIndex = currentDesignsView.findIndex((d) => d.config.id === selectedText.config.id)
    let tempLayers = [...currentDesignsView]
    tempLayers[targetLayerIndex].config.align = alignment
    setCurrentDesignsView(tempLayers)
  }

  const onChangeTextDecoration = (decoration) => {
    if (!selectedText) return
    let currentDecorations = selectedText.config.textDecoration.split(' ')

    if (currentDecorations.includes(decoration)) {
      currentDecorations = currentDecorations.filter((d) => d !== decoration)
    } else {
      currentDecorations.push(decoration)
    }

    const targetLayerIndex = currentDesignsView.findIndex((d) => d.config.id === selectedText.config.id)
    let tempLayers = [...currentDesignsView]
    tempLayers[targetLayerIndex].config.textDecoration = currentDecorations.join(' ')
    setCurrentDesignsView(tempLayers)
  }

  const isTextDecorationActive = (decoration) => {
    if (!selectedText) return false
    let currentDecorations = selectedText.config.textDecoration.split(' ')

    return currentDecorations.includes(decoration)
  }

  return (
    <Stack spacing={3} justifyContent={{ base: 'space-between', lg: 'unset' }} py={{ base: '3', lg: '10' }} px="3" boxShadow={{ base: '0 -4px 8px 0px #00000014', lg: '-4px 0 8px 0px #00000014' }}>
      <Stack direction={{ base: 'row', lg: 'column' }}>
        <IconButton isDisabled={isDisabled} onClick={() => designAlign('HorizontalCenter')} background="white" icon={<MdAlignHorizontalCenter size="25px" />} />
        <IconButton isDisabled={isDisabled} onClick={() => designAlign('VerticalCenter')} background="white" icon={<MdAlignVerticalCenter size="25px" />} />
        <IconButton isDisabled={isDisabled} onClick={() => designAlign('HorizontalLeft')} background="white" icon={<MdAlignHorizontalLeft size="25px" />} />
        <IconButton isDisabled={isDisabled} onClick={() => designAlign('HorizontalRight')} background="white" icon={<MdAlignHorizontalRight size="25px" />} />
        <IconButton isDisabled={isDisabled} onClick={() => designAlign('VerticalBottom')} background="white" icon={<MdAlignVerticalBottom size="25px" />} />
        <IconButton isDisabled={isDisabled} onClick={() => designAlign('VerticalTop')} background="white" icon={<MdAlignVerticalTop size="25px" />} />
      </Stack>

      {selectedText && (
        <>
          <Divider />
          <Stack direction={{ base: 'row', lg: 'column' }}>
            <IconButton onClick={() => onChangeTextAlign('left')} variant={!isTextAlignActive('left') ? 'ghost' : undefined} icon={<MdFormatAlignLeft size="25px" />} />
            <IconButton onClick={() => onChangeTextAlign('center')} variant={!isTextAlignActive('center') ? 'ghost' : undefined} icon={<MdFormatAlignCenter size="25px" />} />
            <IconButton onClick={() => onChangeTextAlign('right')} variant={!isTextAlignActive('right') ? 'ghost' : undefined} icon={<MdFormatAlignRight size="25px" />} />

            <IconButton onClick={() => onChangeTextDecoration('underline')} variant={!isTextDecorationActive('underline') ? 'ghost' : undefined} icon={<MdFormatUnderlined size="25px" />} />
            <IconButton onClick={() => onChangeTextDecoration('line-through')} variant={!isTextDecorationActive('line-through') ? 'ghost' : undefined} icon={<MdStrikethroughS size="25px" />} />
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default AlignmentTool