import React from 'react'

import { Box } from "@chakra-ui/react"

function Layout({ children, title, py = "2em", hideFooter = false, showWA = true, waOffset = '4em' }) {

  return (
    <>
      <Box id="layout" py={py} color="blue.900" minH="100vh">
        {children}
      </Box>
    </>
  )
}

export function Container(props) {
  return (
    <Box mx="auto" maxW="1208px" px="5" {...props}>
      {props.children}
    </Box>
  )
}

export function Card(props) {
  return (
    <Box bg="white" p="5" shadow="md" overflow={'hidden'} rounded="lg" {...props}>
      {props.children}
    </Box>
  )
}

export function primaryColor(withHover = false) {
  return {
    transition: "all .2s ease-in-out",
    backgroundSize: '100% 100%',
    bgGradient: 'linear(to-l, #00bcd4, #03a9f4)',
    _active: { bgGradient: 'linear(to-l, #00bcd4, #03a9f4)' },
    ...(withHover ? { _hover: { backgroundSize: '200% 100%', transition: 'all .2s ease-in-out' } } : null)
  }
}

export default Layout
