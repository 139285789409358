export const data = {
  "kind": {
    "kind": "tshirt",
    "name": "Kaos",
    "description": null,
    "price": 0,
    "weight": 250,
    "created_at": "2021-03-30T04:30:09.000000Z",
    "updated_at": "2021-03-30T04:30:09.000000Z",
    "deleted_at": null,
    "surfaces": [
      {
        "surface": "back",
        "kind": "tshirt",
        "name": "Belakang",
        "additional_price": 0,
        "description": null,
        "created_at": "2021-03-30T04:30:09.000000Z",
        "updated_at": "2021-03-30T04:30:09.000000Z",
        "sizes": [
          {
            "surface": "back",
            "size": {
              "size": "A3",
              "name": "A3",
              "width": 30,
              "height": 40,
              "additional_price": 45000,
              "capital_price": 30000,
              "description": null,
              "created_at": "2021-08-16T04:48:59.000000Z",
              "updated_at": "2021-08-16T04:48:59.000000Z"
            },
            "created_at": "2021-08-16T04:48:59.000000Z",
            "updated_at": "2021-08-16T04:48:59.000000Z"
          },
          {
            "surface": "back",
            "size": {
              "size": "A4",
              "name": "A4",
              "width": 20,
              "height": 28,
              "additional_price": 35000,
              "capital_price": 15000,
              "description": null,
              "created_at": "2021-08-16T04:48:59.000000Z",
              "updated_at": "2021-08-16T04:48:59.000000Z"
            },
            "created_at": "2021-08-16T04:48:59.000000Z",
            "updated_at": "2021-08-16T04:48:59.000000Z"
          },
          {
            "surface": "back",
            "size": {
              "size": "A5",
              "name": "A5",
              "width": 15,
              "height": 25,
              "additional_price": 32000,
              "capital_price": 10000,
              "description": null,
              "created_at": "2021-08-16T04:48:59.000000Z",
              "updated_at": "2021-08-16T04:48:59.000000Z"
            },
            "created_at": "2021-08-16T04:48:59.000000Z",
            "updated_at": "2021-08-16T04:48:59.000000Z"
          },
          {
            "surface": "back",
            "size": {
              "size": "A6",
              "name": "A6",
              "width": 10,
              "height": 15,
              "additional_price": 30000,
              "capital_price": 7500,
              "description": null,
              "created_at": "2021-08-16T04:48:59.000000Z",
              "updated_at": "2021-08-16T04:48:59.000000Z"
            },
            "created_at": "2021-08-16T04:48:59.000000Z",
            "updated_at": "2021-08-16T04:48:59.000000Z"
          },
          {
            "surface": "back",
            "size": {
              "size": "A7",
              "name": "A7",
              "width": 7,
              "height": 10,
              "additional_price": 27500,
              "capital_price": 5000,
              "description": null,
              "created_at": "2021-08-16T04:48:59.000000Z",
              "updated_at": "2021-08-16T04:48:59.000000Z"
            },
            "created_at": "2021-08-16T04:48:59.000000Z",
            "updated_at": "2021-08-16T04:48:59.000000Z"
          }
        ]
      },
      {
        "surface": "front",
        "kind": "tshirt",
        "name": "Depan",
        "additional_price": 0,
        "description": null,
        "created_at": "2021-03-30T04:30:09.000000Z",
        "updated_at": "2021-03-30T04:30:09.000000Z",
        "sizes": [
          {
            "surface": "front",
            "size": {
              "size": "A3",
              "name": "A3",
              "width": 30,
              "height": 40,
              "additional_price": 45000,
              "capital_price": 30000,
              "description": null,
              "created_at": "2021-08-16T04:48:59.000000Z",
              "updated_at": "2021-08-16T04:48:59.000000Z"
            },
            "created_at": "2021-08-16T04:48:59.000000Z",
            "updated_at": "2021-08-16T04:48:59.000000Z"
          },
          {
            "surface": "front",
            "size": {
              "size": "A4",
              "name": "A4",
              "width": 20,
              "height": 28,
              "additional_price": 35000,
              "capital_price": 15000,
              "description": null,
              "created_at": "2021-08-16T04:48:59.000000Z",
              "updated_at": "2021-08-16T04:48:59.000000Z"
            },
            "created_at": "2021-08-16T04:48:59.000000Z",
            "updated_at": "2021-08-16T04:48:59.000000Z"
          },
          {
            "surface": "front",
            "size": {
              "size": "A5",
              "name": "A5",
              "width": 15,
              "height": 25,
              "additional_price": 32000,
              "capital_price": 10000,
              "description": null,
              "created_at": "2021-08-16T04:48:59.000000Z",
              "updated_at": "2021-08-16T04:48:59.000000Z"
            },
            "created_at": "2021-08-16T04:48:59.000000Z",
            "updated_at": "2021-08-16T04:48:59.000000Z"
          },
          {
            "surface": "front",
            "size": {
              "size": "A6",
              "name": "A6",
              "width": 10,
              "height": 15,
              "additional_price": 30000,
              "capital_price": 7500,
              "description": null,
              "created_at": "2021-08-16T04:48:59.000000Z",
              "updated_at": "2021-08-16T04:48:59.000000Z"
            },
            "created_at": "2021-08-16T04:48:59.000000Z",
            "updated_at": "2021-08-16T04:48:59.000000Z"
          },
          {
            "surface": "front",
            "size": {
              "size": "A7",
              "name": "A7",
              "width": 7,
              "height": 10,
              "additional_price": 27500,
              "capital_price": 5000,
              "description": null,
              "created_at": "2021-08-16T04:48:59.000000Z",
              "updated_at": "2021-08-16T04:48:59.000000Z"
            },
            "created_at": "2021-08-16T04:48:59.000000Z",
            "updated_at": "2021-08-16T04:48:59.000000Z"
          }
        ]
      }],
    "variants": [{
      "code": "color",
      "name": "Color",
      "kind": "tshirt",
      "description": null,
      "created_at": "2021-03-30T04:30:09.000000Z",
      "updated_at": "2021-03-30T04:30:09.000000Z",
      "options": [{
        "id": 6,
        "variant_code": "color",
        "name": "Black",
        "description": null,
        "additional_price": 0,
        "capital_price": 0,
        "created_at": "2021-03-30T04:30:09.000000Z",
        "updated_at": "2021-03-30T04:30:09.000000Z"
      },
      {
        "id": 7,
        "variant_code": "color",
        "name": "White",
        "description": null,
        "additional_price": 0,
        "capital_price": 0,
        "created_at": "2021-03-30T04:30:09.000000Z",
        "updated_at": "2021-03-30T04:30:09.000000Z"
      }
      ]
    },
    {
      "code": "material",
      "name": "Material",
      "kind": "tshirt",
      "description": null,
      "created_at": "2021-03-30T04:30:09.000000Z",
      "updated_at": "2021-03-30T04:30:09.000000Z",
      "options": [
        {
          "id": 10,
          "variant_code": "material",
          "name": "Premium",
          "description": null,
          "additional_price": 35000,
          "capital_price": 30000,
          "created_at": "2021-03-30T04:30:09.000000Z",
          "updated_at": "2021-03-30T04:30:09.000000Z"
        },
        {
          "id": 11,
          "variant_code": "material",
          "name": "Super Premium",
          "description": null,
          "additional_price": 50000,
          "capital_price": 40500,
          "created_at": "2021-03-30T04:30:09.000000Z",
          "updated_at": "2021-03-30T04:30:09.000000Z"
        }
      ]
    },
    {
      "code": "size",
      "name": "Sizes",
      "kind": "tshirt",
      "description": null,
      "created_at": "2021-03-30T04:30:09.000000Z",
      "updated_at": "2021-03-30T04:30:09.000000Z",
      "options": [
        {
          "id": 1,
          "variant_code": "size",
          "name": "L",
          "description": null,
          "additional_price": 0,
          "capital_price": 0,
          "created_at": "2021-03-30T04:30:09.000000Z",
          "updated_at": "2021-03-30T04:30:09.000000Z"
        },
        {
          "id": 2,
          "variant_code": "size",
          "name": "S",
          "description": null,
          "additional_price": 0,
          "capital_price": 0,
          "created_at": "2021-03-30T04:30:09.000000Z",
          "updated_at": "2021-03-30T04:30:09.000000Z"
        },
        {
          "id": 3,
          "variant_code": "size",
          "name": "M",
          "description": null,
          "additional_price": 0,
          "capital_price": 0,
          "created_at": "2021-03-30T04:30:09.000000Z",
          "updated_at": "2021-03-30T04:30:09.000000Z"
        },
        {
          "id": 4,
          "variant_code": "size",
          "name": "XL",
          "description": null,
          "additional_price": 0,
          "capital_price": 0,
          "created_at": "2021-03-30T04:30:09.000000Z",
          "updated_at": "2021-03-30T04:30:09.000000Z"
        },
        {
          "id": 5,
          "variant_code": "size",
          "name": "XXL",
          "description": null,
          "additional_price": 10000,
          "capital_price": 0,
          "created_at": "2021-03-30T04:30:09.000000Z",
          "updated_at": "2021-03-30T04:30:09.000000Z"
        }
      ]
    },
    {
      "code": "sleeve",
      "name": "Sleeves",
      "kind": "tshirt",
      "description": null,
      "created_at": "2021-03-30T04:30:09.000000Z",
      "updated_at": "2021-03-30T04:30:09.000000Z",
      "options": [
        {
          "id": 8,
          "variant_code": "sleeve",
          "name": "Short Sleeves",
          "description": null,
          "additional_price": 0,
          "capital_price": 0,
          "created_at": "2021-03-30T04:30:09.000000Z",
          "updated_at": "2021-03-30T04:30:09.000000Z"
        },
        {
          "id": 9,
          "variant_code": "sleeve",
          "name": "Long Sleeves",
          "description": null,
          "additional_price": 10000,
          "capital_price": 10000,
          "created_at": "2021-03-30T04:30:09.000000Z",
          "updated_at": "2021-03-30T04:30:09.000000Z"
        }
      ]
    }
    ]
  },
  "themes": [
    {
      "id": 7,
      "name": "Gamis",
      "slug": "gamis",
      "created_at": "2022-07-19T04:56:46.000000Z",
      "updated_at": "2022-07-19T04:56:46.000000Z"
    },
    {
      "id": 1,
      "name": "Anime",
      "slug": "anime",
      "created_at": "2022-07-19T04:47:45.000000Z",
      "updated_at": "2022-07-19T04:47:45.000000Z"
    },
    {
      "id": 2,
      "name": "Game",
      "slug": "game",
      "created_at": "2022-07-19T04:47:45.000000Z",
      "updated_at": "2022-07-19T04:47:45.000000Z"
    },
    {
      "id": 3,
      "name": "Meme",
      "slug": "meme",
      "created_at": "2022-07-19T04:47:45.000000Z",
      "updated_at": "2022-07-19T04:47:45.000000Z"
    },
    {
      "id": 4,
      "name": "Marvel",
      "slug": "marvel",
      "created_at": "2022-07-19T04:47:45.000000Z",
      "updated_at": "2022-07-19T04:47:45.000000Z"
    },
    {
      "id": 5,
      "name": "Kemerdekaan",
      "slug": "kemerdekaan",
      "created_at": "2022-07-19T04:47:45.000000Z",
      "updated_at": "2022-07-19T04:47:45.000000Z"
    },
    {
      "id": 6,
      "name": "UMKM",
      "slug": "UMKM",
      "created_at": "2022-07-19T04:47:45.000000Z",
      "updated_at": "2022-07-19T04:47:45.000000Z"
    }
  ],
  "profit_min": "5000",
  "transaction_fees": "2000",
  "tshirt_base": {
    "front": {
      "white": "https://paeko.id/images/product_base/PAEKO_FRONT_TSHIRT_BASE_WHITE.png",
      "black": "https://paeko.id/images/product_base/PAEKO_FRONT_TSHIRT_BASE_BLACK.png"
    },
    "back": {
      "white": "https://paeko.id/images/product_base/PAEKO_BACK_TSHIRT_BASE_WHITE.png",
      "black": "https://paeko.id/images/product_base/PAEKO_BACK_TSHIRT_BASE_BLACK.png"
    }
  },
  "selected_variants": {
    "color": ["6", "7"],
    "material": ["10", "11"],
    "size": ["1", "2", "3", "4", "5"],
    "sleeve": ["8", "9"]
  },
  "max_file_size": "2048"
}