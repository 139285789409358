import { ChakraProvider } from '@chakra-ui/react';
import { data } from './data';
import Designer from './components/Designer';
import { DesignerContextProvider } from './lib/designerContext';
import { FontContextProvider } from './lib/fontContext';

function App() {
  return (
    <ChakraProvider>
      <DesignerContextProvider>
        <FontContextProvider>
          <Designer
            errors={{}}
            {...data}
          />
        </FontContextProvider>
      </DesignerContextProvider>
    </ChakraProvider>
  );
}

export default App;
